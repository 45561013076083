@import url('https://fonts.googleapis.com/css2?family=Jost:wght@400;500;700&display=swap');

body {
  font-family: 'Jost', 'Roboto', sans-serif;
}

.App {
  text-align: center;
  background-color: #3a5c53;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  display: flex;
  /* position: fixed; */
  /* width: 95%; */

  justify-content: space-between;
  align-items: center;
  color: #E9E3DC;
  min-height: 10vh;
  padding: 0 40px;
  /* background-color: #3a5c53; Change background color as needed */

}

.header-left {
  font-size: 45px;
  text-align: left;
  width: 35%;
}

.header-middle {
  display: flex;
  gap: 10px;
}

.header-button {
  border: none;
  color: #E9E3DC;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 25px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
  transition: opacity 0.3s;
}

.header-button.unselected {
  opacity: 0.2;
}

.header-right {
  /* font-size: 1.5em; */
  cursor: pointer;
  text-align: right;
  width: 25%;
  z-index: 300;
  
}

.hamburger-icon {
  font-size: 25px;
  cursor: pointer;
  /* width: 25%; */
  z-index: 300;
}

.App-body {
  padding: 20px 0px;
}

.gallery-row {
  display: flex;
  justify-content: center;
}

.gallery-item {
  flex: 1;
}

.gallery-image {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.App-footer {
  /* background-color: #282c34; */
  color: white;
  padding: 0 0 40px 0  ;
  font-size: 20px;
  width: 100%;
  bottom: 0;
  text-align: center;
}

@media (max-width: 601px) {
  .header-left,
  .header-middle,
  .header-right {
    font-size: 25px;
    z-index: 1000;
  }
  .header-button{
    z-index: 1000;
  }
  .App-header {
    padding: 0 10px;
    /* background-color: #3a5c53; Change background color as needed */
  
  }
}


@media (min-width: 601px) and (max-width: 1200px) {
  .header-left,
  .header-middle,
  .header-button,
  .header-right {
    font-size: 35px;
    z-index: 1000;
  }
}

@media (min-width: 1201px) {
  .header-left,
  .header-middle,
  .header-button,
  .header-right {
    font-size: 50px;
    z-index: 1000;
  }
}

.App-footer a {
  text-decoration: none; /* Remove default underline */
  color: inherit; /* Inherit color from parent (white) */
}














































/* Existing styles... */

/* App.css */

.App-header {
  /* position: fixed; */
  /* top: 0;
  left: 0; */
  /* width: 100%; */
  /* background-color: #282c34; Change background color as needed */
  /* color: white; */
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: space-between; */
  /* padding: 0 20px; */
  z-index: 1000; /* Ensure header is on top */
}

/* .header-left {
  font-size: 24px;
}

.header-middle {
  display: flex;
}

.header-button {
  margin-right: 20px;
  cursor: pointer;
} */

.unselected {
  opacity: 0.2;
}

.header-right {
  cursor: pointer;
}

/* .hamburger-icon {
  font-size: 24px;
} */

.nav-items {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  /* background-color: #282c34; Change background color as needed */
  color: white;
  padding: 20px;
  text-align: left;
  z-index: 400; /* Ensure nav items are above purple overlay */
}

.nav-items ul {
  list-style-type: none;
  padding: 0;
}

.nav-items li {
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 80px;
}

.purple-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(128, 0, 128, 0.8); /* Purple with 80% opacity */
  z-index: 399; /* Ensure overlay is between nav items and content */
  cursor: pointer;
  animation: colorPropagation 1s forwards; /* Animation for color propagation */
}

@keyframes colorPropagation {
  0% {
    background-color: rgba(128, 0, 128, 0); /* Transparent at start */
  }
  100% {
    background-color: rgba(128, 0, 128, 0.8); /* Fully opaque purple */
  }
}




































































